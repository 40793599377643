@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

* {
  box-sizing: border-box;

}
html{
  min-height:100%;
  height: 100vh;
  height: 100%;
}
body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: #FFFFFF;
  direction: rtl;
  height: 100vh;
  padding: 0;
  min-height:100%;
  height: 100vh;
  height: 100%;

}
.html2canvas-container { width: 1000px !important; height: 2344px !important; }

h1 {
  font-size: 2.5rem;
  color: #2c292b;
}

h2 {
  font-size: 1.5rem;
  color: #2c292b;
  text-align: center;

}

.rtl-text {
  direction: rtl;
  text-align: center;

}
/* Add these styles to your CSS file */

/* Define a modified class to style modified fields */
.modified {
  background-color: #a6dcaf; /* Change to your desired background color */
  border: 1px solid #fabbbb;    /* Change to your desired border style */
  padding: 10px;            /* Adjust padding as needed */
  border-radius: 5px;       /* Add rounded corners if desired */
}

/* Optional: Add some transition for a smooth visual effect */
.modified {
  transition: background-color 0.3s, border 0.3s;
}

/* Optional: You can adjust the styles for the input fields inside the modified fields */
.modified input {
  border: none;            /* Remove input borders within modified fields */
  background: transparent;  /* Make input backgrounds transparent */
}

.button {
  background-color: #1aacac; /* Blue color for the button */
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  direction: rtl;
  margin: 10px;
}

.small-button {
  all: unset;
  align-items: center;
  background-color: #b98c55;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-color: #8d6533;
  border-radius: 42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 52px;
  justify-content: center;
  width: 100px;
  color: white;
  font-family: "Tel Aviv-ModernistBold", Helvetica;

}
.pages-button {
  background-color: #a1b9d2; /* Blue color for the button */
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  direction: rtl;
  margin: 10px;
}


.container {
  display: flex;
  justify-content: center;
  align-items: center; /* Add this line to center vertically */
  flex-direction: row;
  width: 100%;
  margin-top: 0;

  margin-bottom: 0;
}

.title2 {
  color: #333333;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Tel Aviv-BrutalistBold", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  letter-spacing: 0.72px;
}


.custom-input{
  font-family: "Tel Aviv-ModernistRegular";
  color: #515151 !important;
}

/*  margin: 0;*/
/*  padding: 0;*/
/*  box-sizing: border-box;*/
/*  font-family: 'Poppins', sans-serif;*/
/*}*/



/*table {*/
/*  margin: 5rem auto;*/
/*  border-radius: 2rem;*/
/*  border: 1rem hidden #443C68;*/
/*  border-collapse: collapse;*/
/*  box-shadow: 0 0 0 1px black;*/
/*  overflow: hidden;*/
/*  max-width: 80rem;*/
/*}*/

/*li, p, a, td{*/
/*  font-size: 1rem;*/
/*  line-height: 1.6;*/
/*  max-width: 10rem;*/

/*}*/

/*thead {*/
/*  font-size: 1.8rem;*/
/*  background-color: #443C68;*/
/*  color: #fff;*/
/*  max-width: 10rem;*/

/*}*/

/*th, td {*/
/*  border: 1px solid #443C68;*/
/*  min-width: 30rem;*/
/*  padding: 1.5rem;*/
/*  max-width: 10rem;*/
/*  line-height: 1.7;*/
/*}*/

/*td {*/
/*  max-width: 10rem;*/

/*  font-size: 1rem;*/
/*}*/



