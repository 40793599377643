.packages-screen {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.packages-screen .div {
    background-color: #ffffff;
    height: auto;
    overflow: auto;
    width: 100%;
}

.packages-screen .overlap-group {
    height: 111px;
    left: 0;
    top: 0;
    width: 100%;
}


.packages-screen .header {
    background-color: #333333;
    height: 64px;
    left: 0;
    position: absolute;
    width: 100%;
}

.packages-screen .frame {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: center;
    width: 100%;
}

.packages-screen .hazakman-white-logo-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    padding: 4px 0px 0px;
}

.packages-screen .hazakman-white-logo {
    height: 29px;
    width: 100%;
}

.packages-screen .img {
    height: 38px;
    position: relative;
    width: 74px;
}

.packages-screen .frame-2 {
    align-items: flex-start;
    flex-direction: column;
    gap: 48px;
    left: 0;
    top: 100px;
}

.packages-screen .personal-approache {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.packages-screen .title {
    color: #333333;
    direction: rtl;
    font-family: "Tel Aviv-BrutalistBold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.72px;
    line-height: 40px;
    margin-top: -1px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-bottom: -11px;
}

.packages-screen .text-wrapper {
    color: var(--gray-1);
    direction: rtl;
    font-family: "Tel Aviv-ModernistRegular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.48px;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.packages-screen .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    margin-top: -28px;
}

.packages-screen .frame-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
}

.packages-screen .tabs {
    align-items: flex-end;
    display: flex;
    height: 25px;
    justify-content: center;
    width: 390px;
}

.packages-screen .title-wrapper {
    align-items: flex-end;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 2.5px;
    border-color: #b98c55;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    justify-content: center;
    padding: 0px 8px 8px 16px;
}

.packages-screen .title-2 {
    color: #b98c55;
    direction: rtl;
    font-family: "Tel Aviv-ModernistBold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.48px;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.packages-screen .div-wrapper {
    align-items: flex-end;
    border-bottom-style: solid;
    border-bottom-width: 2.5px;
    border-color: #d8d8d8;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    height: 17px;
    justify-content: center;
    padding: 0px 8px 8px 16px;
}

.packages-screen .title-3 {
    color: #505050;
    direction: rtl;
    font-family: "Tel Aviv-ModernistRegular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.48px;
    line-height: 20px;

    text-align: center;
    width: 100%;
}

.packages-screen .frame-5 {
    align-items: flex-start;
    display: inline-flex !important;
    justify-content: center;
    flex: 0 0 auto;
    gap: 20px;
}

.packages-screen .div-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.packages-screen .div-2-tabs {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;


}

.packages-screen .illustratzi-wide {
    background-color: transparent;
    border-radius: 4px;
    height: 160px;
    width: 212px;
    border: 0;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.10);

    margin: 0}

.packages-screen .frame-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;

}

.packages-screen .wide-title {
    color: var(--gray-1);
    direction: rtl;
    font-family: "Tel Aviv-ModernistBold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.42px;
    line-height: 16px;
    text-align: center;
    width: 212px;
}

.packages-screen .frame-7 {
    margin-top: -7px;
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
}

.packages-screen .mini-title {
    color: var(--gray-1);
    direction: rtl;
    font-family: "Tel Aviv-ModernistRegular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.42px;
    line-height: 16px;
    margin-top: -1px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.packages-screen .mini-title-2 {
    color: var(--gray-1);
    font-family: "Tel Aviv-ModernistRegular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.42px;
    line-height: 16px;
    margin-top: -1px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.packages-screen .illustratzi-mini {
    border-radius: 4px;
    height: 160px;
    position: relative;
    width: 100px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.10);
}

.packages-screen .mini-title-3 {
    color: var(--gray-1);
    direction: rtl;
    font-family: "Tel Aviv-ModernistBold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.42px;
    line-height: 16px;
    text-align: center;
}

.packages-screen .frame-8 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 26px;
}

.packages-screen .div-3 {

    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 14px;
    width: 100%;
}

.packages-screen .text-wrapper-2 {
    color: #333333;
    direction: rtl;
    font-family: "Tel Aviv-BrutalistBold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.48px;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 100%;
}


.packages-screen .text {
    color: #333333;
    direction: rtl;
    font-family: "Tel Aviv-ModernistRegular", Helvetica;
    font-size: 16px;
    font-weight: 400;

    letter-spacing: 0.48px;
    line-height: 24px;
    width: 100%;
}

.packages-screen .text li {
    list-style-type: disc; /* You can change this to 'circle', 'square', or other values */
    margin: 0.48px ; /* Adjust the margin as needed */
}

.packages-screen .frame-9 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    width: 100%;

}

.packages-screen .option-card-instance {
    flex: 0 0 auto !important;
}

.packages-screen .design-component-instance-node {
    color: #505050 !important;
}

.packages-screen .button {
    all: unset;
    cursor: pointer;

    align-items: center;
    background-color: #b98c55;
    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-color: #8d6533;
    border-radius: 42px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 52px;
    justify-content: center;
    margin-bottom: -2px;
    width: 298px;
    color: white;
}

.packages-screen .label-text {
    color: var(--m3syslighton-primary);
    direction: rtl;
    font-family: "Tel Aviv-ModernistBold", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.54px;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-bottom: -2px;
}

.packages-screen .frame-wrapper {
    background-color: #333333;
    height: 141px;
    left: 0;
    width: 100%;
    display: grid;
    margin-top: 48px;
}

.packages-screen .frame-10 {
    align-items: center;
    display: flex;
    gap: 16px;
    top: 32px;
    flex-direction: row-reverse;
    justify-content: center;

}

.packages-screen .communication {
    width: 70px; /* Set the desired width for the icons */
    height: 70px; /* Set the desired height for the icons */
}

.packages-screen .p {
    color: #ffffff;
    direction: rtl;
    font-family: "Tel Aviv-ModernistBold", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.54px;
    line-height: 20px;
    width: 143px;
    justify-content: center;
    margin: 0;
    ;

}

.packages-screen .span {
    font-weight: 700;
}

.packages-screen .text-wrapper-3 {
    font-family: "Tel Aviv-ModernistRegular", Helvetica;
}

.slick-dots li{
    margin: 0 0px;
    color: #333333;

}

.slick-slider .slick-list, .slick-slider .slick-track {
    margin-bottom: 16px;
}

.slick-dots li button:before {
    cursor: pointer;

    font-size: 13px;
}