@font-face {
    font-family:'Tel Aviv-ModernistBold';
src: url('./assets/fonts/TelAviv-ModernistBold.woff2') format('woff2');
font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family:'Tel Aviv-ModernistRegular';
    src: url('./assets/fonts/TelAviv-ModernistRegular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;

}



@font-face {
    font-family:'Tel Aviv-BrutalistBold';
    src: url('./assets/fonts/TelAviv-BrutalistBold.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;

}
